<template>
    <div class="mbox">
        <div class="topbox" @click="goback">冠英知多少 提问大抢答
        </div>
        <img class="refimg" src="@/assets/images/nh/ref.png" @click="reflocaltion" />
        <img class="golist" v-if="talkflag" @click="golist" src="@/assets/images/nh/home.png" />
        <div class="mainbox">
            <div class="leftbox">
                <img class="xzy" src="@/assets/images/nh/xzy.gif" />
                <img class="xzybtm" src="@/assets/images/nh/xzybtm.png" />
            </div>
            <div class="prize">
                <div class="qlistbox" v-if="!talkflag">
                    <div :class="activeIndex == i + 1 ? 'qitem active' : actlist.includes(i + 1) ? 'qitem ed' : 'qitem'"
                        v-for="(item, i) of anslist" :key="i">{{ item.title }}</div>
                </div>
                <div id="resultbox" class="resultbox" v-else>
                    <span v-for="(item, i) in talklist" :key="i">
                        <div class="useritem" v-if="item.message.length > 0">
                            <div class="userright">
                                <div class="usertext">{{ item.message }}</div>
                            </div>
                        </div>
                        <div class="aiitem">
                            <div class="airight">
                                <div class="aitext">
                                    <el-button type="text" v-if="item.reply_content.result == ''"
                                        icon="el-icon-loading">正在分析结果</el-button>
                                    <span v-else>
                                        <!-- <div>总结：</div> -->
                                        <span v-if="!iszytalk" @click="readans">{{ item.reply_content.result }}</span>
                                        <vue-markdown v-else :source="item.reply_content.result"
                                            @click="item.reply_content.result" class="custom-markdown"></vue-markdown>

                                    </span>
                                </div>
                            </div>
                        </div>
                    </span>

                </div>
                <div class="inputbox" v-if="talkflag">
                    <input id="qmess" outline="none" type="hidden" placeholder="点我开始智问…" />
                    <img src="@/assets/images/nh/yy.png" alt="">
                    <div class="audiotip" v-if="!islu" @click="handleStart">点击开始聆听答案</div>
                    <div class="audiotiping" v-else @click="handleStop">已回答{{ recorder.duration.toFixed(2) }}秒，点击完成回答
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="btmbtnsbox">
            <div class="btns" @click="startLuck" :style="{ display: ing ? 'none' : '' }">抽取问题</div>
            <div class="btns" @click="gozytalk" :style="{ display: ing ? 'none' : '' }">自由问答</div>
        </div>
        <audio id="ansaudio" :src="audiosrc" style="display: none;" />
        <audio id="cjaudio" src="@/assets/ding.mp3" style="display: none;" />
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import { pdnhquestion, readquetext } from '@/api/bot'
import { baseUrl } from '@/axios/baseUrl'
import Recorder from 'js-audio-recorder'
export default {
    components: { VueMarkdown },
    data() {
        return {
            activeIndex: null, // 当前抽中的奖品
            lucky: null, // 抽中的商品
            // 奖品数据
            list: [],
            actlist: [],
            recorder: null,
            talkflag: false,
            talklist: [],
            islu: false,
            audiosrc: '',
            iszytalk: false,
            anslist: [
                {
                    "title": "冠英科技集团公司全名是什么？",
                    "ans": "江西冠英智能科技股份有限公司"
                },
                {
                    "title": "冠英技术中心今年拿到的省级奖项是什么？",
                    "ans": "江西省省级企业技术中心"
                },
                {
                    "title": "冠英科技集团分别在哪些地方有办公地？",
                    "ans": "河南、河北、深圳、赣州、南昌、杭州"
                },
                {
                    "title": "公司的服务模式是什么",
                    "ans": "链群配+ 产业大脑 + 共享工厂"
                },
                {
                    "title": "请说出CBoxAI版和CBox2.0的最大区别。",
                    "ans": "算力更高、体积更小、性能更强。"
                },
                {
                    "title": "冠英OS是什么",
                    "ans": "冠英数字底座的可视化操作系统界面。对标windows、小米澎湃OS、华为鸿蒙OS。搭载了：缘启、物和、视溯、湖光、悟空，五大自研平台 与 冠英生态软件。"
                },
                {
                    "title": "CBoxAI版分为几个版本，区别是什么？",
                    "ans": "分为C3000-A6和C3000-A16版本，区别是一个是6TOPS算力，一个是16TOPS算力。"
                },
                {
                    "title": "GBox分为几个版本？",
                    "ans": "数字版、业务版、孪生版、AI迷你版、AI标准版、AI大模型版"
                },
                {
                    "title": "CBox有什么功能？",
                    "ans": "数据采集、边缘计算、设备控制、视觉分析。"
                },
                {
                    "title": "对比DTU和PLC，CBox有哪些优势？",
                    "ans": "性能、算力、AI边缘计算、模型推理、智能控制、交互方面有优势。"
                },
                {
                    "title": "公司在今年拿到的省级综合型工业互联网平台荣誉的平台名字是什么？",
                    "ans": "冠英共享智造工业互联网平台"
                },
                {
                    "title": "请说出几个“晓知因”智控机器人行业系列产品名字",
                    "ans": "鲁小班、铜小铝、盐小氟、垚小稀、牛么么……"
                },
                {
                    "title": "公司的行业中心分别为哪几个行业",
                    "ans": "产业大脑、有色压延、安全应急、轻工消费"
                },
                {
                    "title": "冠英产业互联网六大服务环节是什么？",
                    "ans": "市场需求、产品创新、生产智造、仓储物流、集群配置、销售售后"
                },
                {
                    "title": "冠英科技集团的三大产业方向是什么？",
                    "ans": "未来产业、传统产业、战略新兴产业"
                },
                {
                    "title": "江西冠英智能科技股份有限公司成立时间？",
                    "ans": "2019年6月3日"
                },
                {
                    "title": "冠英的两大品牌体系是什么？",
                    "ans": "面对B端：智享，做高品质工业品供应；面对C端：乐享，做高品质消费品供应"
                },
                {
                    "title": "我们与中国工程院、北京交通大学共建的重点实验室主要四大攻坚技术是什么？",
                    "ans": "①智能机器人标准化与柔性制造技术；②多源异构数据采集融合技术；③智能制造AI优化与决策技术；④共享智造网络控制技术"
                },
                {
                    "title": "CBoxAI版拥有16TOPS算力的是哪个版本？",
                    "ans": "C3000-A16版本"
                },
                {
                    "title": "PEU是指什么",
                    "ans": "集群要素单元，包含集群能力和集群环境两个维度，其中集群能力包括但不限于产业变现能力，产业竞争力、数字化赋能水平、产业工业水平、产业供应链资源；集群环境包括但不限于产业历史文化、产业人才水平、产业上下游距离、独特的地理位置、产业政策条件"
                },
                {
                    "title": "我们CGBox产品全名叫什么",
                    "ans": "冠英CGBox工业AI数字底座"
                },
                {
                    "title": "CBox的全名是什么",
                    "ans": "CBox边缘智控终端"
                },
                {
                    "title": "冠英是哪一年拿的国家级专精特新小巨人",
                    "ans": "2023"
                },
                {
                    "title": "我们冠英CGBox工业AI数字底座的口号是？",
                    "ans": "灵活组网、简单易用、AI智控、省人省钱"
                },
                {
                    "title": "针对铜基材料交易，我们建设的网站叫什么名字？",
                    "ans": "鑫材网"
                },
                {
                    "title": "冠英在2023年拿到的关于消费品的一个国家级奖项是什么？",
                    "ans": "国家消费品行业数字化转型促进中心"
                },
                {
                    "title": "针对竹基材料交易，我们建设的网站叫什么名字？",
                    "ans": "好竹易"
                },
                {
                    "title": "集群品牌运营的三要素",
                    "ans": "SPU、SKU、PEU"
                },
                {
                    "title": "我们谢盛主任的实验室全名叫什么",
                    "ans": "面向产业集群的共享智造部级重点实验室"
                },
                {
                    "title": "我们目前所在的园区全称叫什么",
                    "ans": "冠英数智未来产业园区"
                },
                {
                    "title": "我们正在规划建设的园区叫什么？",
                    "ans": "冠英AI未来产业先导园"
                },
                {
                    "title": "我们的视觉模型有哪些",
                    "ans": "安全帽识别模型、口罩识别模型、火点识别模型、闯入识别模型"
                },
                {
                    "title": "冠英在赣州的哪些县建设过标杆项目",
                    "ans": "定南、会昌、瑞金、南康"
                },
                {
                    "title": "冠英科技集团的愿景是什么",
                    "ans": "以数智科技助力产业高质量发展"
                },
                {
                    "title": "冠英核心产品的部署方式有什么特色",
                    "ans": "云-边-端"
                },
                {
                    "title": "江西省的1269指的是什么",
                    "ans": "12条制造业重点产业链，6个综合实力和竞争力强的先进制造业集群，规上企业收入年均增长9%"
                },
                {
                    "title": "江西省企业数字化综合发展水平通过什么进行评定",
                    "ans": "入企诊断"
                },
                {
                    "title": "江西省制造业诊断等级一共有多少级？",
                    "ans": "L1-L10，一共十级"
                },
                {
                    "title": "从L1-L10的不同级别中，有多少个阶段？分别是什么",
                    "ans": "准备阶段（L1、L2级）、基础爬坡阶段（L3-L5级）、集成提升阶段（L6-L8级）、创新领航阶段（L9、L10级）"
                },
                {
                    "title": "江西省制造业数字化综合发展水平评价指标体系从哪几方面进行评估？",
                    "ans": "技术、管理、生产、产品、服务、效益 六方面进行评估"
                },
                {
                    "title": "产业运营公司下属的三大事业部分别是哪些",
                    "ans": "铜基新材事业部、永磁新材事业部、竹基新材事业部"
                },
                {
                    "title": "园区运营部是哪个中心的二级部门",
                    "ans": "行政运营中心"
                },
                {
                    "title": "针对未来产业，我们先期布局哪三个领域？",
                    "ans": "未来制造、未来信息、未来材料"
                },
                {
                    "title": "CBox有几个版本，分别是什么？",
                    "ans": "C1000,C2000,C3000"
                },
                {
                    "title": "Gbox的全称是什么",
                    "ans": "GBox边缘数据中心"
                },
                {
                    "title": "CGBox工业数字底座能做到哪些事情",
                    "ans": "采控算   训推服"
                },
                {
                    "title": "冠英科技集团产业互联网平台的名字",
                    "ans": "海选好品"
                },
                {
                    "title": "我们廖泽帆廖总所在的工业设计中心的定位是什么？",
                    "ans": "推动“中国产品转向中国品牌”朝着更高远的国际化品牌拓展"
                },
                {
                    "title": "我们自研的设计AI服务的系统有哪些？",
                    "ans": "冠英模型库、视溯-大模型智能监控平台、冠英AI训练平台、多模态大模型推理系统"
                },
                {
                    "title": "我们在南康的竹基新材产品展厅名字是什么？",
                    "ans": "乐享竹木家居全球体验中心"
                },
            ],
            ing: false
        }
    },
    computed: {

    },
    mounted() {
        console.log(JSON.parse(window.localStorage.getItem('actlist')))
        this.actlist = JSON.parse(window.localStorage.getItem('actlist')) || []
    },
    methods: {
        reflocaltion() {
            window.localStorage.removeItem('actlist')
            history.go(0)
        },
        goback() {
            this.$router.push('/gindex')
        },
        readans() {
            document.getElementById('ansaudio').play()
        },
        gozytalk() {
            this.talkflag = true
            this.iszytalk = true
            this.talklist = []
        },
        golist() {
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = null
            this.lucky = null
        },
        gotalk() {
            this.talkflag = true
            const message = this.anslist[this.lucky - 1].title
            if (message == '') return;
            this.talklist.push({
                message: '',
                reply_content: {
                    result: message
                }
            })
            const element = document.getElementById('resultbox')
            setTimeout(() => {
                element.scrollTo({
                    x: 0,
                    top: element.scrollHeight,
                    behavior: 'smooth'
                })

            }, 300)
            // this.connectSever(message);

        },
        // 传的item是点击当前元素
        startLuck() {
            this.ing = true;
            document.getElementById('cjaudio').play()
            const rannum = Math.floor(Math.random() * 50)
            console.log(this.actlist, rannum, this.actlist.includes(rannum))
            if (this.actlist.includes(rannum) || rannum == 0) {
                this.startLuck()
                return;
            }
            // 如果当前元素的id不是btn，就不执行
            // if (item.id !== 'btn') return
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = null
            this.lucky = null
            setTimeout(() => { // 随机抽中奖品
                this.lucky = rannum
                this.toread(this.anslist[rannum - 1].title)
                this.ing = false;
                // readquetext({
                //     "text": "冠英科技集团公司全名是什么?",
                //     "voice": "zh-CN-YunyangNeural",
                //     "speed": 10
                // }).then(res => {
                //     console.log(res)

                //     this.audiosrc = res.audio
                //     setTimeout(() => {
                //         this.readans()
                //     }, 500);
                // })

                // console.log('奖品是' + this.lucky) // 返回的是索引
                // console.log('奖品是' + this.list[this.lucky].text)

            }, 7500)
            // 执行动画
            setTimeout(() => {
                this.move(20)
            }, 1000);

        },
        toread(text) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl + '/audioapi/tts', true);
            const formData = new FormData()
            formData.append('text', text)
            formData.append('voice', "zh-CN-YunyangNeural")
            formData.append('speed', -10)
            // const url = window.URL.createObjectURL(fileOfBlob)
            // this.src = url
            xhr.send(formData);
            const that = this;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const res = JSON.parse(xhr.responseText);
                    console.log(res)
                    this.audiosrc = res.audio
                    setTimeout(() => {
                        this.readans()
                    }, 500);
                }
            }
        },
        move(time) {
            if (time > 2000) { // time大于600就减速执行
                if (this.activeIndex !== this.lucky) { // 如果this.activeIndex 不等于 抽中的奖品this.lucky
                    // setTimeout(() => {
                    //     const t = this.activeIndex + 1
                    //     this.activeIndex = t % 50
                    //     this.move(time + time * 0.001)
                    // }, time)
                    this.activeIndex = this.lucky
                    this.actlist.push(this.lucky)
                    console.log(this.actlist)
                    setTimeout(() => {
                        window.localStorage.setItem('actlist', JSON.stringify(this.actlist))
                    }, 300);

                    this.gotalk()
                    return;
                } else {
                    // console.log('抽中的奖品是' + this.activeIndex) // 返回的是索引
                    // console.log('抽中的奖品是' + this.list[this.activeIndex].text)
                }
            } else { // time小于600就原速执行
                setTimeout(() => {
                    const t = Math.floor(Math.random() * 50)
                    this.activeIndex = t
                    this.move(time * 1.014)
                    // console.log(time)
                }, 20)
                // }
            }
        },
        // 开始录音
        handleStart() {
            this.recorder = new Recorder()
            Recorder.getPermission().then(() => {
                console.log('开始录音')
                this.islu = true;
                this.recorder.start() // 开始录音
            }, (error) => {
                this.$message({
                    message: '请先允许该网页使用麦克风',
                    type: 'info'
                })
                console.log(`${error.name} : ${error.message}`)
            })
        },

        handleStop() {
            console.log('停止录音')
            this.islu = false;
            this.recorder.stop() // 停止录音
            setTimeout(() => {
                this.uploadRecord()
            }, 200);
        },
        uploadRecord() {
            if (this.recorder == null || this.recorder.duration === 0) {
                this.$message({
                    message: '请先录音',
                    type: 'error'
                })
                return false
            }
            this.recorder.pause() // 暂停录音
            this.timer = null
            console.log('上传录音')// 上传录音
            let xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl + '/audioapi/asr', true);
            const formData = new FormData()
            const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
            // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
            const newbolb = new Blob([blob], { type: 'audio/wav' })
            const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
            formData.append('file', fileOfBlob)
            // const url = window.URL.createObjectURL(fileOfBlob)
            // this.src = url
            xhr.send(formData);
            const that = this;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const res = JSON.parse(xhr.responseText);
                    console.log(res)
                    setTimeout(() => {

                        setTimeout(() => {
                            if (res.text == '') {
                                this.toread('我貌似没有听到你的消息，请再试一次把')
                            } else {
                                this.talklist.push({
                                    message: res.text,
                                    reply_content: {
                                        result: ''
                                    }
                                })
                                that.connectSever(res.text)
                            }

                        }, 200);

                    }, 100);
                }
            }

        },
        connectSever(message) {
            if (this.iszytalk) {
                const that = this;
                that.talklist[that.talklist.length - 1].reply_content.result = '正在思考...'
                console.log('进来了几次')

                that.eventSource = new EventSource(`${baseUrl}/queapi/robot_writing/gygpt_chat?robotId=13&query=${message}&userId=99`)


                that.eventSource.addEventListener('open', (event) => {
                    that.isconn = true;
                    console.log('连接已建立', JSON.stringify(event))

                })
                that.eventSource.onmessage = (event) => {
                    that.isstop = true;
                    // console.log(event.data)
                    const data = JSON.parse(event.data)

                    console.log(data)
                    if (!data) {
                        that.talklist[that.talklist.length - 1].reply_content.result = '正在思考...'
                        return
                    }
                    if (that.talklist[that.talklist.length - 1].reply_content.result == '正在思考...') {

                        that.talklist[that.talklist.length - 1].reply_content.result = ''
                    }
                    if (data.id) {
                        this.historyid = data.id
                    }

                    that.isshowcw = true
                    if (data.answer == '[DONE]') {
                        that.talklist[that.talklist.length - 1].reply_content.relatedquestion = data.relatedquestion;
                        return;
                    }
                    if (data.id) {
                        this.talkguanlianid = data.id
                        return;
                    }

                    // that.anslist = that.anslist + data.answer

                    that.talklist[that.talklist.length - 1].reply_content.imagereplys = data.imagereplys ? data.imagereplys : data.imagereply ? [data.imagereply] : null
                    that.talklist[that.talklist.length - 1].reply_content.result = that.talklist[that.talklist.length - 1].reply_content.result + data.answer


                    if (data.answer.includes('\n') || that.talklist[that.talklist.length - 1].reply_content.result.length / 40 == 0 || data.answer.includes('图') || data.answer.includes('，')) {
                        that.$nextTick(() => {
                            const element = document.getElementById('resultbox')
                            element.scrollTo({
                                x: 0,
                                top: Number.MAX_SAFE_INTEGER,
                                behavior: 'smooth'
                            })
                        })
                    }


                }
                that.eventSource.addEventListener('eventName', (event) => {
                    console.log('Received event:', event.data);
                });
                that.eventSource.onerror = (event) => {
                    that.isconn = false;
                    console.log('连接已断开')
                    console.log(that.historyid)
                    that.isstop = false;
                    that.isshowcw = false;
                    that.$nextTick(() => {
                        that.isshowcw = true;
                        setTimeout(() => {
                            const element = document.getElementById('resultbox')
                            element.scrollTo({
                                x: 0,
                                top: Number.MAX_SAFE_INTEGER,
                                behavior: 'smooth'
                            })
                            that.toread(that.talklist[that.talklist.length - 1].reply_content.result)
                        }, 300);


                    })
                    // setTimeout(() => {

                    //   that.isshowcw = false
                    // }, 1000);    .replace('http://47.96.179.72:11454/','/klgapi/')
                    console.log(that.talklist[that.talklist.length - 1].reply_content)
                    if (that.talklist[that.talklist.length - 1].reply_content.imagereplys) {
                        that.talklist[that.talklist.length - 1].reply_content.imagereplys.forEach(item => {
                            that.srcList.push(item.replace('http://47.96.179.72:11454/', 'https://gpt-all.web.guanyingis.com/klgapi/'))
                        })
                        // that.srcList.push(...that.talklist[that.talklist.length - 1].reply_content.resultimgs)
                    }
                    console.log(that.srcList)
                    that.eventSource.close()
                }
            } else {
                pdnhquestion({
                    "question": this.anslist[this.lucky - 1].title,
                    "answer": this.anslist[this.lucky - 1].ans,
                    "useranswer": message
                }).then(res => {
                    console.log(res)
                    this.talklist[this.talklist.length - 1].reply_content = {
                        result: res.text
                    }
                    this.audiosrc = res.audio
                    setTimeout(() => {
                        this.readans()
                    }, 500);
                })
            }

        },
    }
}
</script>
<style lang="less" scoped>
.mbox {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/nh/bg.jpeg');
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    padding: 0 20px;
    display: flex;
    overflow: hidden;

    .refimg {
        width: 30px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    .golist {
        position: absolute;
        top: 20px;
        right: 400px;
        width: 30px;
        cursor: pointer;
    }

    .topbox {
        width: 600px;
        height: 88px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: calc(50% - 300px);
        background-image: url('../assets/images/nh/topbg.png');
        background-size: cover;
        background-repeat: no-repeat;
        font-size: 30px;
        text-align: center;
        color: #fff;
        letter-spacing: 10px;
        line-height: 58px;
    }

    .mainbox {
        display: flex;
        width: 100%;
        height: calc(100% - 120px);
        margin-top: 100px;


        .leftbox {
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: start;
            position: relative;

            .xzy {
                width: 400px;
            }

            .xzybtm {
                position: absolute;
                width: 200px;
                left: 40px;
                top: 280px;
            }
        }

        .prize {
            width: calc(100% - 300px);
            height: calc(100% - 70px);
            overflow-x: hidden;
            position: relative;

            .qlistbox {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;

                .qitem {
                    width: calc(10% - 25px);
                    height: 140px;
                    margin: 10px;
                    overflow: hidden;
                    text-align: center;
                    padding: 10px;
                    box-sizing: border-box;
                    background-color: #C8459160;
                    color: #fff;
                    font-size: 16px;
                    border-radius: 6px;

                    &.active {
                        background: #CF5BA0;
                        box-shadow: 0px 0px 20px 0px #CF5BA0, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
                        border-radius: 8px;
                        border: 1px solid #f895ff8f;
                        cursor: pointer;
                        -webkit-backdrop-filter: blur(7px);
                        backdrop-filter: blur(7px);

                    }




                }

                .ed {
                    opacity: 0.3;
                }
            }

            .resultbox {
                width: 100%;
                height: calc(100% - 100px);
                overflow-y: auto;
                overflow-x: hidden;

                .custom-markdown {
                    width: 100%;
                    height: 100%;
                    font-size: 18px;
                    overflow-y: auto;
                    word-break: break-all;
                }

                .custom-markdown {
                    /deep/ .table {
                        border-collapse: collapse;
                        border-spacing: 0;
                    }

                    /deep/ .table {
                        border-top: 1px solid #333;
                        border-left: 1px solid #333;
                    }

                    /deep/ .table tbody tr td,
                    /deep/ .table thead tr th {
                        border-right: 1px solid #333;
                        border-bottom: 1px solid #333;
                        padding: 6px;
                    }
                }

                .aiitem {
                    display: flex;
                    width: calc(100% + 20px);
                    position: relative;
                    margin: 20px 0;


                    .airight {
                        min-width: 180px;
                        max-width: calc(100% - 80px);
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        padding-left: 10px;
                        text-align: left;
                        margin-bottom: 10px;
                        background: rgba(12, 2, 20, 0.4);
                        box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 31px 0px #FF81ED;
                        border: 2px solid;
                        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(231, 216, 232, 1)) 2 2;
                        backdrop-filter: blur(7px);
                        position: relative;
                        cursor: pointer;



                        .name {
                            font-size: 18px;
                            color: #333333;
                            margin-bottom: 10px;

                            span {
                                font-size: 12px;
                                background: #F8F8F9;
                                border-radius: 6px;
                                padding: 4px 8px;
                                margin-left: 10px;
                            }
                        }

                        .aitext {
                            // width: 500px;
                            width: 100%;
                            color: #fff;
                            padding: 10px 20px;
                            font-size: 20px;



                            .loadinggif {
                                height: 52px;

                                img {
                                    height: 52px;
                                }
                            }


                            .custom-markdown code,
                            .custom-markdown /deep/ pre,
                            .custom-markdown pre code {
                                white-space: break-spaces;
                                word-break: break-all;
                                font-size: 16px;
                                /* 换行 */
                            }


                        }


                    }
                }

                .useritem {
                    position: relative;
                    color: white;
                    width: calc(100% - 10px);
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row-reverse;



                    .userright {
                        // background: #FF009D;
                        padding-right: 10px;
                        text-align: left;
                        float: right;
                        margin: 10px 0;
                        max-width: 800px;
                        margin-left: 40px;

                        .usertext {
                            background: rgba(12, 2, 20, 0.4);
                            box-shadow: 0px 0px 20px 0px #81BBFF, inset 0px 0px 31px 0px #92C3FF;
                            border: 2px solid;
                            border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(231, 216, 232, 1)) 2 2;
                            backdrop-filter: blur(7px);
                            color: #fff;
                            font-size: 20px;
                            padding: 10px 20px;
                            white-space: pre-wrap;
                        }
                    }
                }

                position: relative;


            }




        }

        .inputbox {
            position: absolute;
            bottom: 20px;
            width: calc(100% - 1px);
            display: flex;
            padding: 4px 10px;
            background: rgba(12, 2, 20, 0.59);
            box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
            border-radius: 8px;
            border: 4px solid #FF81ED;
            backdrop-filter: blur(7px);
            justify-content: center;
            align-items: center;

            img {
                width: 45px;
                height: 45px;
                margin: 0 10px;
                cursor: pointer;
            }

            button {
                background: transparent;
                border: 0;
            }

            .audiotip {
                width: calc(100% - 30px);
                color: #fff;
                height: 70%;
                border-radius: 20px;
                text-align: center;
                line-height: 40px;
                font-size: 20px;
                letter-spacing: 5px;
                cursor: pointer;
            }

            .audiotiping {
                width: calc(100% - 30px);
                color: #fff;
                height: 70%;
                border-radius: 20px;
                text-align: center;
                line-height: 40px;
                font-size: 20px;
                cursor: pointer;
            }

            .center {
                width: calc(100% - 110px);

                textarea {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    outline: none;
                    color: #333;
                    font-size: 20px;
                    font-family: Alibaba PuHuiTi;
                    resize: none;
                    background-color: transparent;
                    white-space: pre-wrap;
                    white-space: pre-wrap;
                }

                input:focus {
                    outline: none;
                }

                input::placeholder {
                    color: #333;
                    font-weight: 100;
                }
            }
        }

        /deep/ .el-button--text {
            color: #fff !important;
        }

    }

    .btmbtnsbox {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 100%;
        display: flex;
        justify-content: center;

        .btns {
            margin-left: 20px;
            width: 218px;
            font-size: 20px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            height: 45px;
            background: rgba(12, 2, 20, 0.59);
            box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
            border-radius: 8px;
            border: 4px solid #f895ff8f;
            letter-spacing: 5px;
            cursor: pointer;
            backdrop-filter: blur(7px);
        }
    }

    /deep/ .el-button--text {
        color: #fff !important;
    }

}

/deep/ .el-button--text {
    color: #fff !important;
}
</style>