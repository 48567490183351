<template>
  <div class="usermain">
    <div class="userbox">
      <div class="uleft" @click="userheadflag = true">
        <el-tooltip class="item" effect="dark" content="点击修改头像" placement="top">
          <img :src="userinfo.head || require('./img/userhead.png')" />
        </el-tooltip>

      </div>
      <div class="uright">
        <div v-if="!isedit" class="uname">{{ userinfo.nickname || '未设置' }}</div>
        <el-input v-else v-model="editname" @blur="savename" placeholder="请输入昵称"></el-input>
        <div class="editbox" @click="goedit">修改 ></div>
      </div>
    </div>
    <div class="infobox">
      <div class="sititle">
        <div class="point"></div>账号设置
      </div>
      <div class="infoitem">
        <div class="uname">账号绑定：{{ userinfo.phone }}</div>
      </div>
    </div>
    <el-dialog title="更换头像" :modal-append-to-body="false" :visible="userheadflag" :before-close="hanclose"
      width="650px">
      <div class="textbox">
        <div class="upbox">
          <div class="utitle">选择本地图片，上传编辑头像</div>
          <el-upload class="upload-demo" :before-upload="upload" drag action="">

            <img src="./img/uploadimg.png" style="width:120px;margin: 20px 0" />
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload>
          <p class="upwebtip"><span style="color: #ff009d;">*</span> 支持拖拽、点击上传图片文件，最多上传一张</p>
        </div>
        <div class="showbox">
          <div class="utitle">头像预览</div>
          <div class="ylhead">
            <div><img :src="head || require('./img/userhead.png')" /></div>
          </div>
        </div>
      </div>
      <div class="btnbox">
        <div class="btnitem goxt" @click="userheadflag = false">取消</div>
        <div class="btnitem sum" @click="changeuserinfo">确认</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { baseUrl } from '@/axios/baseUrl'
import { editUserInfo } from '@/api/user'
export default {
  name: '',
  components: {},
  data() {
    return {
      userinfo: [],
      isedit: false,
      editname: '',
      userheadflag: false,
      head: null
    }
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem('userInfo'))
    console.log(this.userinfo)
  },
  props: {
    changeuser: { type: Function }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    hanclose() {
      this.userheadflag = false
    },
    goedit() {
      this.isedit = true
      this.editname = this.userinfo.nickname
    },
    savename() {
      console.log(this.editname)
      editUserInfo({ id: this.userinfo.id, nickname: this.editname }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '修改成功'
          });
          this.isedit = false;
          this.userinfo = res.data
          setTimeout(() => {
            localStorage.setItem('userInfo', JSON.stringify(this.userinfo))
            localStorage.setItem('token', this.userinfo.token)
            this.$emit('changeuser', this.userinfo)
          }, 100);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
    upload(file) {
      console.log(file)
      if (!file) return; // 没有选择文件
      this.uploading = true;
      this.$message.success('上传中..');
      let xhr = new XMLHttpRequest();
      const formdata = new FormData();
      formdata.append('file', file)
      xhr.open('POST', baseUrl + '/api/common/uploadImage', true);
      xhr.setRequestHeader('Authorization', localStorage.getItem("token"))
      xhr.send(formdata);
      const that = this;
      xhr.onreadystatechange = () => {
        console.log(xhr.status)
        if (xhr.readyState === 4 && xhr.status === 200) {
          const res = JSON.parse(xhr.responseText);
          if (res.code == 200) {
            that.$message.success(res.msg)
            that.head = res.data
          } else {
            that.$message.error(res.msg)
          }
          console.log(res)
        }
      }

    },
    changeuserinfo() {
      if (!this.head) {
        this.$message.error('请先上传头像')
      }
      editUserInfo({ id: this.userinfo.id, head: this.head }).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '修改成功'
          });
          this.userheadflag = false;
          this.userinfo = res.data
          setTimeout(() => {
            localStorage.setItem('userInfo', JSON.stringify(this.userinfo))
            localStorage.setItem('token', this.userinfo.token)
            this.$emit('changeuser', this.userinfo)
          }, 100);
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
      // setTimeout(() => {
      //   localStorage.setItem('userInfo', JSON.stringify(this.userinfo))
      //   this.$emit('changeuser', this.userinfo)
      //   this.userheadflag = false
      // }, 100);
    }
  }
}
</script>
<style lang='less' scoped>
.usermain {
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 30px;

  .userbox {
    background-color: #fff;
    width: 100%;
    padding: 20px;
    display: flex;
    border-radius: 12px;

    .uleft {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .uright {
      margin-left: 20px;
      color: #333;
      display: flex;
      flex-wrap: wrap;
      align-content: space-around;
    }

    .uname {
      font-size: 20px;
      width: 100%
    }

    .editbox {
      font-size: 16px;
      color: #0389f8;
      cursor: pointer;
    }
  }

  .infobox {
    margin-top: 50px;
    width: 100%;

    .sititle {
      display: flex;
      align-items: center;
      font-size: 20px;
      color: #333;


      .point {
        width: 10px;
        height: 10px;
        margin-right: 12px;
        border-radius: 50%;
        background-color: #0389f8;
      }
    }

    .infoitem {
      background-color: #fff;
      width: 100%;
      padding: 20px;
      margin-top: 12px;
      width: 100%;
      display: flex;
      border-radius: 12px;
      color: #333;
      font-size: 16px;
    }
  }

  .textbox {
    .upwebtip {
      font-size: 13px;
      text-align: left;
      margin-bottom: 15px;
      color: #4E5E83;
    }
  }
}

/deep/ .el-dialog {
  border-radius: 13px;

  .el-upload-dragger .el-upload__text em {
    color: #0389f8;
  }

  .btnbox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .btnitem {
      width: 100px;
      height: 35px;
      color: #0389f8;
      border: 1px solid #0389f8;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      border-radius: 3px;
      margin-left: 20px;
      cursor: pointer;
    }

    .sum {
      background: #0389f8;
      color: #fff;
      border: 0;
    }
  }


  .utitle {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }

  .textbox {
    display: flex;

    .upbox {
      padding: 10px 30px;
      border-right: 1px solid #ffffff30;
    }

    .showbox {
      width: 200px;
      padding: 10px 30px;

      .ylhead {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }

      }

    }
  }
}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  font-size: 22px
}

/deep/ .el-upload-dragger {
  background-color: transparent;

}
</style>
