<template>
  <div :class="level == '自建知识库' || clist.includes(userInfo.phone)
    ? 'klgmain3 '
    : 'klgmain3 klgmain0'
    ">
    <div class="klgcontent">
      <div class="klgtablebox">
        <div class="nodatabox" @click="goupload"
          v-if="filelist.length == 0 && (level == '自建知识库' || clist.includes(userInfo.phone))">
          <img src="../img/uploadimg.png" alt="" />
          <div class="nodatatxt">点击图片,录入知识库吧</div>
          <!-- <div class="nodatatxt"><el-button size="small" @click="goupload"
              v-if="level == '自建知识库' || clist.includes(userInfo.phone)">上传文档</el-button>
          </div> -->
        </div>
        <span v-else>
          <div class="upbtndiv">
            <!-- <span class="filecount">共<span class="cnum">{{ filelist.length }}</span>个文件，已训练<span class="cnum">{{
        filelist.length }}</span>个</span> -->
            <div class="upbtn" @click="goupload" v-if="level == '自建知识库' || clist.includes(userInfo.phone)">
              上传文档
            </div>
          </div>
          <el-table :data="filelist" :height="updownflag ? '480' : '720'" border style="width: 100%">
            <el-table-column align="center" type="index" center label="序号" width="80" />
            <el-table-column prop="name" label="文件名称" />
            <el-table-column align="center" label="文件大小" width="120">
              <template slot-scope="scope">
                <div>
                  {{
    scope.row.size
      ? parseFloat(scope.row.size / 1000000).toFixed(2)
      : 0
  }}MB
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="creatorName" label="创建人" width="120">
              <template slot-scope="scope">
                <div>{{ level == '自建知识库' ? scope.row.creatorName : '官方' }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" width="180">
              <template slot-scope="scope">
                <div>{{ scope.row.createTime }}</div>
              </template>
            </el-table-column>
            <el-table-column align="left" label="操作" width="260"
              v-if="level == '自建知识库' || clist.includes(userInfo.phone)">
              <template slot-scope="scope">
                <div>
                  <!-- <el-button size="mini" @click="download" type="success">下载</el-button> -->
                  <el-button size="mini" v-if="scope.row.status == 0 && scid != scope.row.id" type="primary"
                    @click="createquelist(scope.row.id, scope.row.knowledgeBaseId)">生成问答列表</el-button>
                  <el-button size="mini" v-if="scope.row.status == 2" type="primary"
                    @click="showquelist(scope.row)">审阅问题</el-button>
                  <el-button size="mini" v-if="scope.row.status == 3" type="success">审阅完成</el-button>
                  <el-button size="mini" type="success" @click="download(scope.row.url)">导出</el-button>
                  <el-button size="mini" v-if="isscing && scid == scope.row.id" type="primary"
                    disabled>生成中..</el-button>
                  &nbsp;
                  <el-popconfirm title="确定删除选中文件吗？" @confirm="delfile(scope.row.id)">
                    <el-button slot="reference" size="mini" type="danger">删除</el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="No" label="状态" width="180"> -->
            <!-- </el-table-column> -->
          </el-table>

          <div class="pagelist">
            <div class="1left">
              <!-- <el-pagination background page-size="5" :total="filelist.length">
              </el-pagination> -->
            </div>
            <div class="right1">文档集已有文件{{ filelist.length }}个</div>
          </div>
        </span>
      </div>
    </div>

    <el-dialog center :title="'审阅问题（待审核' + cquelist.length + '条）'" :append-to-body="true" :visible="shstatus"
      width="700px" :before-close="shClose">
      <div class="nodata" v-if="cquelist.length == 0">暂无待审核问题</div>
      <div class="mydialog" v-else>
        <p style="margin: 0 0 10px 0">问题：</p>
        <el-input placeholder="请输入问题" v-model="cqtitle"> </el-input>
        <p style="margin: 30px 0 10px 0">答案：</p>
        <el-input placeholder="请输入答案" type="textarea" v-model="cqans" :rows="10">
        </el-input>

      </div>
      <span slot="footer" class="dialog-footer" v-if="cquelist.length > 0">
        <el-button :disabled="cqindex == 0" @click="pagec(0)">上一个</el-button>
        <el-button type="danger" @click="qyquestions">全部弃用</el-button>
        <el-button type="danger" @click="qyque">弃用</el-button>
        <el-button type="primary" @click="saveque">转存</el-button>
        <el-button type="primary" @click="savequestions">全部转存</el-button>
        <el-button :disabled="cqindex == (cquelist.length - 1)" @click="pagec(1)">下一个</el-button>
      </span>
    </el-dialog>
    <el-dialog title="上传文档" :append-to-body="true" :visible="uploadstatus" width="630px" :before-close="shClose">
      <el-tabs v-model="upmenu">
        <el-tab-pane label="文件上传" name="file"></el-tab-pane>
        <el-tab-pane label="文本上传" name="txt"></el-tab-pane>
        <el-tab-pane label="网页采集" name="web"></el-tab-pane>
        <el-tab-pane label="公众号采集" name="wx"></el-tab-pane>
      </el-tabs>
      <div style="width: 100%; text-align: center" v-if="upmenu == 'file'" v-loading="uploading"
        element-loading-text="努力上传中">
        <p class="upwebtip">
          支持上传TXT、XML、DOCX、PPT、 PPTX、XLS格式文件。
          文档支持30MB以内，单次最多上传三个文件。
        </p>
        <el-upload class="upload-demo" :before-upload="filechange" multiple drag action="">
          <img src="../img/uploadimg.png" style="width: 150px; margin: 20px 0" />
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <div style="width: 100%; text-align: right" v-else-if="upmenu == 'txt'">
        <div class="mydialog">
          <el-input placeholder="请输入问题" v-model="uptitle" style="margin-bottom: 15px">
          </el-input>
          <el-input placeholder="请输入内容" type="textarea" v-model="upcont" :rows="8">
          </el-input>
          <div class="upwebtip" style="margin-top: 10px;">Tip:答案文本框右下角可以拖动大小方便查看</div>
        </div>
        <el-button type="primary" style="margin-top: 20px;" @click="saveuptxt">保 存</el-button>
      </div>
      <div style="width: 100%; text-align: right" v-else-if="upmenu == 'web'">
        <div class="upwebtip">
          请避免非法抓取他人网站的侵权行为，保证链接可公开访问，且网站内容可复制<br />
          可抓取的网页仅为内容固定不变的静态网页，例如新闻文章、产品介绍等
        </div>
        <el-input placeholder="输入想要采集的网页地址，用英文,隔开" type="textarea" v-model="upweburl" :rows="4">
        </el-input>
        <el-button type="primary" style="margin-top: 20px;" @click="saveupweb">采 集</el-button>
      </div>
      <div style="width: 100%; text-align: right" v-else-if="upmenu == 'wx'">
        <div class="upwebtip">
          请避免未经授权抓取他人公众号原创文章的侵权行为
        </div>
        <div class="wxitem">
          <div class="wxitenleft">搜索名称</div>
          <el-autocomplete class="inline-input" v-model="wxname" style="width: 100%" :fetch-suggestions="querySearch"
            placeholder="请输入公众号名称" :trigger-on-focus="false" @select="handleSelect">
            <template slot-scope="{ item }">
              <div class="wxselectname">
                <img :src="item.head" />{{ item.value }}
              </div>
            </template>
          </el-autocomplete>
        </div>
        <div class="wxitem" style="margin-top: 30px">
          <div class="wxitenleft">采集内容</div>
          <el-select style="width: 100%" v-model="wxnum" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <el-button type="primary" style="margin-top: 20px;" @click="savewx">采集</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addQuestion, addhemltodoc, addtxttodoc, getwxdatalist, saveWxQuestions, getzjFilelist, savezjFile, createFileQuelist, delzjFile, getCreateFileQueList } from '@/api/klg'
import { baseUrl } from '@/axios/baseUrl'
export default {
  name: '',
  components: {

  },
  data(props) {
    return {
      filelist: [],
      fileed: 0,
      klgid: props.klgid,
      klgname: props.klgname,
      type: 'first',
      inputtitle: '',
      inputcontent: '',
      updownflag: props.updownflag,
      page: 1,
      wdcount: 0,
      smenu: '问答集',
      dialogVisible: false,
      quedetail: [],
      isscing: false,
      cquelist: [],
      shstatus: false,
      cqindex: 0,
      fileid: 0,
      level: props.level,
      cqans: '',
      cqtitle: '',
      wxlist: [],
      uptitle: '',
      upcont: '',
      cqloading: false,
      uploadstatus: false,
      uploading: false,
      updata: [],
      upmenu: 'file',
      upweburl: '',
      wxnum: '10',
      wxname: '',
      wxed: '',
      scid: 0,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      syfileinfo: [],
      syqueinfo: [],
      fileurl: '',
      clist: ['18007099098', '18057127368', '18870710935', '15070216300', '17826823305'],
      options: [{
        value: '10',
        label: '最近10条'
      }, {
        value: '20',
        label: '最近20条'
      }, {
        value: '50',
        label: '最近50条'
      }, {
        value: '100',
        label: '最近100条'
      }]
    }
  },
  props: {
    klgid: { type: Number },
    klgname: { type: String },
    level: { type: String },
    updownflag: { type: Boolean }
  },
  mounted() {
    this.getlist()

  },
  watch: {
    klgid(n) {
      console.log(n, 'data')
      this.klgid = n;
      this.getlist()
    },
    klgname(n) {
      console.log(n, 'klgname')
      this.klgname = n;
    },
    level(n) {
      console.log(n, 'level')
      this.level = n;
    },
    updownflag(n) {
      console.log(n, 'updownflag')
      this.updownflag = n;
    },
  },
  methods: {
    fileclick(e) {
      this.fileed = e
    },
    querySearch(e, cb) {
      console.log('输入', e)
      // getwxlistbyquery({ query: e, page: 1 }).then(res => {
      //   console.log(res)
      // })
      let xhr = new XMLHttpRequest()
      xhr.open('GET', baseUrl + '/api/trainingPromotionCenter/select_wechat_official_account?query=' + e + '&page=1')
      xhr.setRequestHeader('Authorization', localStorage.getItem("token"))
      xhr.send();
      const that = this;
      const alist = []
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          res.data.forEach(item => {
            alist.push({
              value: item.nickname,
              head: item.round_head_img,
              fakeid: item.fakeid
            })
          });
          console.log(res)
        }
      }
      cb(alist);
    },
    handleSelect(e) {
      console.log(e)
      this.wxed = e
    },
    handleClose() {
      this.dialogVisible = false
    },
    shClose() {
      this.shstatus = false;
      this.uploadstatus = false
    },
    typeclick(e) {
      this.type = e
    },
    savewx() {
      const params = {
        kbId: this.klgid,
        fakeid: this.wxed.fakeid,
        count: this.wxnum,
        account: this.wxed.value
      }
      this.$message.success('采集中请稍候..')
      this.uploadstatus = false
      getwxdatalist(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success('保存成功')
          setTimeout(() => {
            this.getlist()
          }, 100);
        } else {
          this.$message.error(res.msg)
        }
      })

    },
    saveuptxt() {
      console.log(this.uptitle, this.upcont)
      const params = {
        kbId: this.klgid,
        title: this.uptitle,
        content: this.upcont
      }

      addtxttodoc(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.uploadstatus = false
          setTimeout(() => {
            this.getlist()
          }, 100);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    saveupweb() {

      const params = {
        kbId: this.klgid,
        url: this.upweburl
      }

      addhemltodoc(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success('保存成功')
          this.uploadstatus = false
          setTimeout(() => {
            this.getlist()
          }, 100);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    savequestions() {
      const params = {
        documentId: this.syfileinfo.id,
        knowledgeBaseId: this.syfileinfo.knowledgeBaseId,
        status: 1,
        reviewQuestionAnswerList: this.cquelist,
      }

      addQuestion(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.shstatus = false
          this.$message.success(res.msg)
          setTimeout(() => {
            this.getlist()
          }, 100);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    upmenuclick(e) {
      console.log(e)
      this.upmenu = e
    },
    editque(row) {
      this.dialogVisible = true;
      this.inputcontent = row.answer;
      this.inputtitle = row.question_title;
      this.quedetail = row;
    },
    gettimeresult(date) {
      return new Date(date * 1000).getFullYear() + '-' + (new Date(date *
        1000).getMonth() + 1) + '-' + new Date(date * 1000).getDate()
    },
    openadddialog() {
      this.dialogVisible = true;
      this.inputcontent = '';
      this.inputtitle = '';
      this.quedetail = [];
    },
    createquelist(id, klgid) {
      if (this.isscing == true) {
        this.$message.error('正在生成其他文件请等待')
        return;
      }

      this.scid = id;
      this.isscing = true
      this.$message.success('正在AI生成，请稍候')
      const params = {
        documentId: id,
        knowledgeBaseId: klgid
      }
      createFileQuelist(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getlist()
          this.isscing = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showquelist(item) {
      this.syfileinfo = item
      const params = {
        id: item.id
      }
      getCreateFileQueList(params).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.cquelist = res.data;
          if (res.data.length > 0) {
            this.syqueinfo = res.data[0]
            this.cqans = res.data[0].answer;
            this.cqtitle = res.data[0].question;
          }

          this.shstatus = true
          console.log(res)
        } else {
          this.$message.error(res.msg)
        }
      })

    },
    pagec(type) {
      this.cqindex = type == 0 ? (this.cqindex - 1) : (this.cqindex + 1);
      this.syqueinfo = this.cquelist[this.cqindex]
      this.cqans = this.cquelist[this.cqindex].answer;
      this.cqtitle = this.cquelist[this.cqindex].question;
    },
    saveque() {
      const params = {
        documentId: this.syfileinfo.id,
        knowledgeBaseId: this.syfileinfo.knowledgeBaseId,
        status: 1,
        reviewQuestionAnswerList: [this.syqueinfo],
      }

      addQuestion(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.showquelist(this.syfileinfo)
          }, 100);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    qyque() {
      const params = {
        documentId: this.syfileinfo.id,
        knowledgeBaseId: this.syfileinfo.knowledgeBaseId,
        status: 2,
        reviewQuestionAnswerList: [this.syqueinfo],
      }

      addQuestion(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.shstatus = true
          setTimeout(() => {
            this.showquelist(this.syfileinfo)
          }, 100);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    qyquestions() {
      const params = {
        documentId: this.syfileinfo.id,
        knowledgeBaseId: this.syfileinfo.knowledgeBaseId,
        status: 2,
        reviewQuestionAnswerList: this.cquelist,
      }

      addQuestion(params).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.shstatus = false
          this.$message.success(res.msg)
          setTimeout(() => {
            this.getlist()
          }, 100);
        } else {
          this.$message.error(res.msg)
        }
      })
    },


    back() {
      this.$emit('changetype', 'ai')
    },
    getlist() {
      const params = {
        knowledgeBaseId: this.klgid,
        page: this.page,
        pageSize: '10'
      }
      getzjFilelist(params).then(res => {
        console.log('问答集', res)
        this.filelist = res.data.list
      })
    },
    download(url) {
      window.open(url)
    },
    goupload() {
      // console.log(document.getElementById('inputfile'))
      // document.getElementById('inputfile').click();
      if (this.klgid == 0) {
        this.$message.error('请先创建自己的知识库')
        return;
      }
      this.uploadstatus = true;
    },
    filechange(e) {
      console.log(e)
      this.upload(e)
    },
    upload(file) {
      console.log(file)
      if (!file) return; // 没有选择文件
      this.uploading = true;
      this.$message.success('上传中..');
      let xhr = new XMLHttpRequest();
      const formdata = new FormData();
      formdata.append('files', file)
      // formdata.append('files', file)
      formdata.append('knowledgeBaseId', this.klgid)
      xhr.open('POST', baseUrl + '/api/trainingPromotionCenter/uploadFilesSync', true);

      xhr.setRequestHeader('Authorization', localStorage.getItem("token"))
      xhr.send(formdata);
      const that = this;
      xhr.onreadystatechange = () => {
        console.log(xhr.status)
        if (xhr.readyState === 4 && xhr.status === 200) {

          const res = JSON.parse(xhr.responseText);
          if (res.code == 200) {
            that.fileurl = res.data
            that.tongbufile(file, res.data)
          } else {
            that.$message.error(res.message)
          }
          // that.savefile(res.data, file.size, file.name)
          console.log(res)
        }
      }

    },
    tongbufile(file, url) {
      console.log(file)
      if (!file) return; // 没有选择文件
      if (file.size / 1024 / 1024 / 20 >= 1) {
        this.$message.error('文件大小不允许超过20MB');
        return;
      }
      var xhr = new XMLHttpRequest();
      // 处理上传进度
      xhr.upload.onprogress = function (event) {
        var percent = 100 * event.loaded / event.total;
        console.log('上传进度：' + percent + '%');
      };
      // this.$message.error('上传失败');
      xhr.onerror = function () {
        this.$message.error('上传失败');
      }
      // 发送请求
      xhr.open('POST', baseUrl + '/klgapi/knowledge_base/upload_docs', true);

      xhr.timeout = 1000 * 600;
      var formData = new FormData();
      formData.append('files', file);
      formData.append('knowledge_base_name', this.klgname)
      xhr.send(formData);
      const that = this
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {  //固定写法
          //数据获取成功，获取服务器响应的数据
          const res = JSON.parse(xhr.responseText)
          console.log(res)
          if (res.code == 200) {
            that.$message.success(res.msg)
            that.savefile(url, file.size, file.name)
          } else {
            that.$message.error(res.msg)
          }
        }
      }
    },
    savefile(fileurl, size, name) {
      const params = {
        size: size,
        name: name,
        knowledgeBaseId: this.klgid,
        url: fileurl,
        status: 0,
        aiGenerate: false
      }
      console.log(params)
      savezjFile(params).then(res => {
        console.log('新增文件', res)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.uploading = false;
          setTimeout(() => {

            this.getlist()
          }, 500);
          this.uploadstatus = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    delfile(id) {
      delzjFile([id]).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.getlist()
          }, 100);
        } else {
          this.$message.error(res.msg)
        }

      })
    },


  }
}
</script>
<style lang='less' scoped>
.klgmain3 {
  width: 100%;
  border-radius: 10px 0 0 10px;
  margin-top: -35px;
  font-family: Alibaba PuHuiTi;
  padding: 0 20px;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
  }



  .bottop {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding: 20px 30px;
    font-size: 20px;

    .menuitem {
      width: 300px;
      height: 40px;
      cursor: pointer;
      background: linear-gradient(275deg, #22254d 0%, #272a57 100%);
      border-radius: 12px;
      color: white;
      text-align: center;
      line-height: 40px;
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }

    .med {
      background: #333;
    }

    /deep/ .el-tabs__item {
      font-size: 18px;
      text-decoration: 10px;
      letter-spacing: 2px;
    }

    .back {
      position: absolute;
      left: 20px;
      top: 15px;
      cursor: pointer;

      .icon {
        width: 50px;
      }
    }

    .botleft {
      width: calc(100% - 100px);
      height: 80px;
      padding: 10px 20px;
      background-color: white;
      font-size: 32px;
      text-align: center;
      font-weight: bold;
      border-radius: 8px;
      line-height: 60px;
    }

    .bottopright {
      width: 80px;
      height: 80px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 32px;
      }
    }
  }

  .klgcontent {
    border-radius: 10px;
    overflow: hidden;
    height: calc(100% - 60px);

    .fbbox {
      position: relative;
      height: 20px;

      .fbtop {
        height: 40px;
        background: rgba(28, 34, 54, 0.06);
        border-radius: 6px;
        display: flex;
        width: fit-content;
        position: absolute;
        bottom: -40px;
        left: 20px;

        .fbitem {
          height: 32px;
          width: 80px;
          border-radius: 5px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          margin: 4px;
          font-size: 14px;
          background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
          color: #333;
        }

        .checked {
          background: #333;
          color: #fff;
        }
      }
    }

    .detailbox {
      padding: 0 20px;

      .detail {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-top: 10px;

        .detailitem {
          text-align: center;
          margin: 0 20px;

          .dname {
            color: #dbdbff;
            font-size: 16px;
          }

          .dvalue {
            font-size: 20px;
            background: linear-gradient(to right, #9953ff, #53a9ff);
            /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
            -webkit-background-clip: text;
            /*将设置的背景颜色限制在文字中*/
            -webkit-text-fill-color: transparent;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .tcont {
        font-size: 16px;
        display: flex;
        background: linear-gradient(180deg, #181b40 0%, #181a37 100%);
        border-radius: 12px;
        margin-top: 10px;

        .zskitem {
          color: #7a7a9d;
          margin: 10px 20px;
          height: 30px;
          line-height: 30px;
        }

        .zname {
          font-size: 18px;
          color: #333;
        }
      }
    }

    .nodatabox {
      cursor: pointer;
      width: 50%;
      text-align: center;
      margin: 30px auto;

      .nodatatxt {
        font-size: 24px;
        margin-top: 30px;

        color: #333;
      }

      img {
        margin-top: 10%;
        width: 40%;
      }
    }

    .uploadbox {
      width: 100%;
      text-align: center;
      padding: 20px;
      border-radius: 8px;
      position: relative;

      #inputfile {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      img {
        height: 60px;
      }

      .tip {
        font-size: 18px;
        margin-top: 20px;
      }
    }

    .klgtablebox {
      width: 100%;
      text-align: center;
      border-radius: 8px;
      padding-top: 0;

      .upbtndiv {
        width: 100%;
        display: flow-root;
        font-size: 20px;
        text-align: right;

        .filecount {
          background-color: #eeecec;
          font-size: 14px;
          padding: 4px 10px;
          border-radius: 4px;
          margin-right: 15px;

          .cnum {
            color: #0988f6;
            font-size: 16px;
            margin: 0 5px;
            font-weight: bold;
          }
        }

        .upbtn {
          width: 120px;
          height: 32px;
          color: #0988f6;
          border: 1px solid #0988f6;
          border-radius: 8px;
          line-height: 32px;
          font-size: 18px;
          float: right;
          margin-bottom: 10px;
          cursor: pointer;
          text-align: center;
        }
      }

      table {
        width: 100%;
        margin-bottom: 20px;
        border-collapse: collapse;
        border-right: 1px solid #e4e4e4;
        border-top: 1px solid #e4e4e4;

        thead {
          background-color: #f2f2f3;
          color: #fff;
          width: 100%;
          display: inline-table;

          tr {
            th {
              padding: 10px;
              font-size: 16px;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }
        }

        .t1 {
          width: 5%;
        }

        .t2 {
          width: 39%;
        }

        .t3 {
          width: 12%;
        }

        .t4 {
          width: 20%;
        }

        tbody {
          max-height: 550px;
          overflow-y: auto;
          display: block;
          width: 100%;

          tr {
            cursor: pointer;
            display: flex;

            td {
              padding: 10px;
              font-size: 17px;
              border: 0;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }

          .fileed {
            background-color: #cde4f7;
          }
        }
      }

      .pagelist {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 16px;
        line-height: 30px;

        .left1 {}

        .right1 {
          color: #333;
          font-size: 13px;
          margin: 10px 0;
        }
      }
    }
  }

  .btnbox {
    display: flex;
    justify-content: center;

    .btnitem {
      width: 240px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 20px;
      background-color: #263754;
      color: white;
      font-weight: bold;
      border-radius: 8px;
      margin: 20px;
      cursor: pointer;
    }
  }

  .mydialog {
    z-index: 2001;

    p {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 5px;
    }
  }
}

.nodata {
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.upwebtip {
  font-size: 15px;
  text-align: left;
  margin-bottom: 15px;
  color: #4e5e83;
}

.aidiv {
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  margin: 0 auto;
}

.wxitem {
  width: 100%;
  display: flex;

  .wxitenleft {
    width: 100px;
    text-align: left;
    line-height: 35px;
  }
}

.wxselectname {
  display: flex;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.klgmain0 {
  margin-top: 5px;
}

/deep/ .el-table tr {
  background-color: #1c2044 !important;
  color: #ddecff !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #202652 !important;

  color: #333;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table--border {
  border: 1px solid #181a37;
  border-right: 1px solid #181a37;
  border-bottom: 1px solid #181a37;
  color: #ddecff !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fff;
}

/deep/ .el-pagination span {
  font-size: 15px;
}

/deep/ .el-loading-mask {
  background-color: #233855d4;
}

.el-table .el-table__body td,
.el-table .el-table__header th {
  border-right: none;
  border-bottom: none;
}

.el-table .el-table__body td:last-child,
.el-table .el-table__header th:last-child {
  border-right: 1px solid #dcdfe6;
  /* 根据需要可以添加或去掉这一行代码 */
}

.el-table .el-table__footer td,
.el-table .el-table__header th {
  border-bottom: none;
}

/deep/ .el-button {
  margin: 5px;
}

/deep/ .el-popover {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #fff;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
}



/deep/ .el-upload__text {
  font-size: 16px
}

/deep/ .el-pagination__jump,
/deep/ .el-pagination__total {
  color: #ddecfe;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #333;
}

/deep/ .el-upload-dragger {
  background: #fff;
  border-radius: 5px;
  border-color: #1c2044;
}

/deep/ .el-upload-dragger .el-upload__text {
  color: #333;
}

/deep/ .el-upload-dragger .el-upload__text em {
  color: #333;
}

//去除右侧白线
.el-table--border::after {
  width: 0px;
}

.el-table__header-wrapper {
  background: none;
}

.el-table::before {
  background: none;
}

/* 取消el-table鼠标移入高亮 
div /deep/ .el-table tbody tr:hover>td {
  background: #0e1341 !important;
}*/

/* 取消el-table鼠标移入高亮 */
.el-table__body tr:hover>td {
  background-color: transparent !important;
}

/deep/ .el-dialog {
  background: #fff;
  border-radius: 13px;

  .mydialog {
    color: #fff;
    font-size: 18px;

    .tips {
      font-size: 14px;
      margin-top: 10px;
      text-align: left;
    }



  }
}

/deep/ .el-tabs__item {
  font-size: 20px
}






/deep/ .el-tabs__item {
  color: #333;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__active-bar {
  background-color: #0988f6;
}

/deep/ .el-table__body-wrapper {
  background: #1c2044;
}

/deep/ .el-tabs__item.is-active {
  color: #0988f6;
}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #333 !important;
  font-size: 22px;
}
</style>
