<template>
    <div class="mbox">
        <div class="topbox" @click="goback">幸运大抽奖 好运到谁家 </div>
        <img class="refimg" src="@/assets/images/nh/ref.png" @click="reflocaltion" />
        <div class="levelbox" v-if="!isset" @click="isset = true"><img src="@/assets/images/nh/set.png" /></div>
        <div class="levelbox" v-else>
            级别：<select class="levelchange" :value="level" @change="levelchange">
                <option>三等奖</option>
                <option>二等奖</option>
                <option>一等奖</option>
                <option>特等奖</option>
            </select>&nbsp;
            总人数：<input class="myinput" :value="nums" @input="numschange" />&nbsp;
            <div class="mybtns" @click="editdetail">保存</div>
        </div>
        <div class="mainbox">
            <!-- <div class="leftbox">
                <img class="xzy" src="@/assets/images/zqzs/xzy.gif" />
                <img class="xzybtm" src="@/assets/images/nh/xzybtm.png" />
            </div> -->
            <div class="prize">
                <div class="qlistbox" v-if="!talkflag">
                    <div :class="activeIndex.includes(i + 1) ? 'qitem active' : actlisted.includes(i + 1) ? 'qitem ed' : 'qitem'"
                        v-for="(item, i) of list" :key="i">{{ item }}</div>
                </div>

            </div>
        </div>
        <div class="btmbtnsbox">
            <div class="btns" :style="{ display: ing ? 'none' : '' }" @click="goluck">点击抽奖</div>
        </div>
        <audio id="ansaudio" :src="audiosrc" style="display: none;" />
        <div class="dialog" :style="{ display: dialogflag ? '' : 'none' }">
            <div class="detailbox">
                <div class="leveltitle">{{ level }}</div>
                <span style="grid-template-columns: repeat(10, minmax(50px, 1fr));"
                    v-if="level == '三等奖' || level == '二等奖'">
                    <div class="ditem" v-for="(item, i) in lucky" :key="i">
                        <img
                            :src="level == '三等奖' ? require('@/assets/images/nh/3.png') : require('@/assets/images/nh/2.png')" />
                        <div class="num">{{ item }}</div>
                    </div>
                </span>
                <span style="grid-template-columns: repeat(3, minmax(150px, 1fr));" v-if="level == '一等奖'">
                    <div class="ditem l1" v-for="(item, i) in lucky" :key="i">
                        <img src="@/assets/images/nh/1.png" />
                        <div class="num">{{ item }}</div>
                    </div>
                </span>
                <span style="grid-template-columns: repeat(1, minmax(250px, 1fr));" v-if="level == '特等奖'">
                    <div class="ditem l0" v-for="(item, i) in lucky" :key="i">
                        <img src="@/assets/images/nh/4.png" />
                        <div class="num">{{ item }}</div>
                    </div>
                </span>
                <div class="btns" @click="dialogflag = false">点击关闭</div>
            </div>

        </div>
        <audio id="cjaudio" src="@/assets/ding.mp3" style="display: none;" />
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import { pdnhquestion, readquetext } from '@/api/bot'
import { baseUrl } from '@/axios/baseUrl'
import Recorder from 'js-audio-recorder'
export default {
    components: { VueMarkdown },
    data() {
        return {
            activeIndex: [], // 当前抽中的奖品
            lucky: [], // 抽中的商品
            // 奖品数据
            list: [],
            isset: false,
            actlist: [],
            recorder: null,
            talkflag: false,
            levelnum: 10,
            talklist: [],
            islu: false,
            audiosrc: '',
            level: '三等奖',
            nums: 149,
            actlisted: [],
            ing: false,
            iszytalk: false,
            dialogflag: false,

        }
    },
    computed: {

    },
    mounted() {
        for (let index = 1; index < 150; index++) {
            this.list.push(index)
        }
        this.actlisted = JSON.parse(window.localStorage.getItem('actlisted')) || []
    },
    methods: {
        goback() {
            this.$router.push('/gindex')
        },
        readans() {
            document.getElementById('ansaudio').play()
        },
        levelchange(e) {
            console.log(e.target.value)
            this.level = e.target.value
        },
        numschange(e) {
            console.log(e.target.value)
            this.nums = e.target.value
        },
        editdetail() {
            this.list = [];
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = []
            this.lucky = []
            for (let index = 1; index <= this.nums; index++) {
                this.list.push(index)
            }
            console.log(this.level)
            if (this.level == '一等奖') {
                this.levelnum = 3
            } else if (this.level == '特等奖') {
                this.levelnum = 1
            } else {
                this.levelnum = 10
            }
            setTimeout(() => {
                this.isset = false
                this.$message.success('修改成功')
            }, 200);
        },
        gozytalk() {
            this.talkflag = true
            this.iszytalk = true
            this.talklist = []
        },
        golist() {
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = []
            this.lucky = []
        },
        reflocaltion() {
            window.localStorage.removeItem('actlisted')
            history.go(0)
        },
        goluck() {

            if (this.isset) {
                this.$message.error('请先保存设置')
                return;
            }
            this.ing = true;
            document.getElementById('cjaudio').play()
            console.log(this.levelnum)
            for (let index = 0; index < this.levelnum; index++) {
                this.startLuck(index)
            }
            setTimeout(() => {
                this.move(20)
            }, 1000);
        },
        // 传的item是点击当前元素
        startLuck(index) {
            this.ing = true;
            const rannum = Math.floor(Math.random() * this.nums + 1)
            console.log(this.actlisted, rannum, this.lucky)
            if (this.actlisted.length == 0) {
                if (this.lucky.includes(rannum) || rannum == 0) {
                    this.startLuck(index)
                    return;
                }
            }
            if (this.actlisted.includes(rannum) || rannum == 0 || this.lucky.includes(rannum)) {
                this.startLuck(index)
                return;
            }
            setTimeout(() => {
                this.actlisted.push(rannum)
                this.ing = false;
                setTimeout(() => {
                    window.localStorage.setItem('actlisted', JSON.stringify(this.actlisted))
                }, 300);
            }, 7500);
            // 如果当前元素的id不是btn，就不执行
            // if (item.id !== 'btn') return
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = []
            this.lucky[index] = rannum
            // 执行动画

        },
        toread(text) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl + '/audioapi/tts', true);
            const formData = new FormData()
            formData.append('text', text)
            formData.append('voice', "zh-CN-YunyangNeural")
            formData.append('speed', -10)
            // const url = window.URL.createObjectURL(fileOfBlob)
            // this.src = url
            xhr.send(formData);
            const that = this;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const res = JSON.parse(xhr.responseText);
                    console.log(res)
                    this.audiosrc = res.audio
                    setTimeout(() => {
                        this.readans()
                    }, 500);
                }
            }
        },
        move(time) {
            if (time > 2000) { // time大于600就减速执行
                if (this.activeIndex !== this.lucky) { // 如果this.activeIndex 不等于 抽中的奖品this.lucky
                    // setTimeout(() => {
                    //     const t = this.activeIndex + 1
                    //     this.activeIndex = t % 50
                    //     this.move(time + time * 0.001)
                    // }, time)
                    this.activeIndex = []
                    this.dialogflag = true
                    this.actlist = this.lucky

                    return;
                } else {
                    // console.log('抽中的奖品是' + this.activeIndex) // 返回的是索引
                    // console.log('抽中的奖品是' + this.list[this.activeIndex].text)
                }
            } else { // time小于600就原速执行
                setTimeout(() => {
                    const t = Math.floor(Math.random() * this.nums + 1)
                    this.activeIndex.splice(0, 1)
                    this.activeIndex.splice(1, 1)
                    this.activeIndex.splice(2, 1)
                    this.activeIndex.splice(3, 1)
                    this.activeIndex.push(t)
                    this.activeIndex.push(t * 2)
                    this.activeIndex.push(t * 3)
                    this.activeIndex.push(t * 4)
                    this.move(time * 1.015)
                    // console.log(time)
                }, 20)
                // }
            }
        },

    }
}
</script>
<style lang="less" scoped>
.mbox {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/nh/bg.jpeg');
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    padding: 0 20px;
    display: flex;
    overflow: hidden;

    .refimg {
        width: 30px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    .levelbox {
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
        display: flex;
        color: #fff;
        align-items: center;

        img {
            width: 30px;
        }

        .levelchange,
        .myinput {
            width: 100px;
            height: 30px;
            padding: 5px 10px;
            background-color: transparent;
            color: #fff;
            border: 1px solid #9c9c9c;
            outline: none;
        }

        .mybtns {
            border-radius: 4px;
            width: 88px;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            height: 32px;
            background: #dc96d285;
            cursor: pointer;
            line-height: 32px;
            -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
        }
    }

    .topbox {
        width: 600px;
        height: 88px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: calc(50% - 300px);
        background-image: url('../assets/images/nh/topbg.png');
        background-size: cover;
        background-repeat: no-repeat;
        font-size: 30px;
        text-align: center;
        color: #fff;
        letter-spacing: 10px;
        line-height: 58px;
    }

    .mainbox {
        display: flex;
        width: 100%;
        height: calc(100% - 20px);
        margin-top: 100px;


        .leftbox {
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: start;
            position: relative;

            .xzy {
                width: 200px;
            }

            .xzybtm {
                position: absolute;
                width: 200px;
                left: 60px;
                top: 150px;
            }
        }

        .prize {
            width: calc(100% - 80px);
            margin-left: 40px;
            height: calc(100% - 70px);
            overflow-x: hidden;
            position: relative;

            .qlistbox {
                display: grid;
                grid-template-columns: repeat(15, minmax(50px, 1fr));
                gap: 5px;

                /* 容器内的填充 */
                .qitem {
                    width: 80px;
                    height: 57px;
                    margin: 10px;
                    text-align: center;
                    font-size: 20px;
                    padding: 10px;
                    line-height: 40px;
                    box-sizing: border-box;
                    background-color: #C8459160;
                    color: #fff;
                    border-radius: 6px;

                    &.active {
                        width: 80px;
                        height: 57px;
                        line-height: 33px;
                        background: #CF5BA0;
                        box-shadow: 0px 0px 20px 0px #CF5BA0, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
                        border-radius: 8px;
                        border: 4px solid #f895ff8f;
                        cursor: pointer;
                        -webkit-backdrop-filter: blur(7px);
                        backdrop-filter: blur(7px);

                    }




                }

                .ed {
                    opacity: 0.3;
                }
            }






        }



    }

    .btmbtnsbox {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 100%;
        display: flex;
        justify-content: center;

        .btns {
            margin-left: 20px;
            width: 218px;
            font-size: 20px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            height: 45px;
            background: rgba(12, 2, 20, 0.59);
            box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
            border-radius: 8px;
            border: 4px solid #f895ff8f;
            letter-spacing: 5px;
            cursor: pointer;
            backdrop-filter: blur(7px);
        }
    }

    /deep/ .el-button--text {
        color: #fff !important;
    }

    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: #33333390;
        display: flex;
        align-items: center;
        justify-content: center;

        .detailbox {
            width: 80%;
            height: 100px;
            margin-top: -300px;

            .leveltitle {
                font-weight: normal;
                font-size: 80px;
                width: 100%;
                text-align: center;
                color: #FFFFFF;
                line-height: 97px;
                text-shadow: 0px 0px 20px #FF81ED;
            }

            span {
                display: grid;
                align-items: center;
                justify-items: center;
                justify-content: center;
                gap: 20px;

                .ditem {
                    width: 160px;
                    position: relative;

                    img {
                        width: 160px;
                    }

                    .num {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-size: 60px;
                        color: #fff;
                        top: 50px;
                        display: flex;
                        justify-content: center;

                    }
                }

                .l1 {
                    width: 250px;
                    position: relative;

                    img {
                        width: 250px;
                    }

                    .num {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-size: 80px;
                        color: #fff;
                        top: 100px;
                        display: flex;
                        justify-content: center;

                    }
                }

                .l0 {
                    width: 250px;
                    position: relative;

                    img {
                        width: 250px;
                    }

                    .num {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-size: 80px;
                        color: #fff;
                        top: 100px;
                        display: flex;
                        justify-content: center;

                    }
                }
            }

        }

        .btns {
            margin-left: 20px;
            width: 218px;
            font-size: 26px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            height: 60px;
            background: rgba(12, 2, 20, 0.59);
            box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
            border-radius: 8px;
            border: 4px solid #f895ff8f;
            letter-spacing: 5px;
            cursor: pointer;
            backdrop-filter: blur(7px);
            margin: 50px auto;
        }
    }

}

/deep/ .el-button--text {
    color: #fff !important;
}
</style>