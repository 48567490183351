<template>
    <div class="mbox">
        <div class="gitem" @click="gopage(1)">
            <div class="title">冠英知多少</div>
            <img src="@/assets/images/nh/g1.png" />
        </div>
        <div class="gitem" @click="gopage(2)" style="margin: 0 50px;">
            <div class="title">幸运大抽奖</div>
            <img src="@/assets/images/nh/g2.png" />
        </div>
        <div class="gitem" @click="gopage(3)">
            <div class="title">找呀找朋友</div>
            <img src="@/assets/images/nh/g3.png" />
        </div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import { pdnhquestion, readquetext } from '@/api/bot'
import { baseUrl } from '@/axios/baseUrl'
import Recorder from 'js-audio-recorder'
export default {
    components: { VueMarkdown },
    data() {
        return {
            activeIndex: [], // 当前抽中的奖品

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        gopage(i) {
            this.$router.push(i == 1 ? '/nh' : i == 2 ? '/cj' : '/game')
            // setTimeout(() => {
            //     history.go(0)
            // }, 200);
        }
    }
}
</script>
<style lang="less" scoped>
.mbox {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/nh/bg.jpeg');
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    padding: 0 20px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    .gitem {
        width: 400px;
        height: 450px;
        cursor: pointer;

        .title {
            font-weight: normal;
            font-size: 50px;
            width: 100%;
            text-align: center;
            color: #FFFFFF;
            line-height: 97px;
            text-shadow: 0px 0px 20px #FF81ED;
        }

        img {
            width: 100%;
        }
    }


}
</style>