<template>
  <div :class="level == '自建知识库' || clist.includes(userInfo.phone)
    ? 'klgmain3 '
    : 'klgmain3 klgmain0'
    ">
    <div class="klgcontent">
      <div class="klgtablebox">
        <div class="nodatabox" @click="openadddialog"
          v-if="filelist.length == 0 && (level == '自建知识库' || clist.includes(userInfo.phone))">
          <img src="../img/uploadimg.png" alt="" />
          <div class="nodatatxt">点击图片,录入知识库吧</div>
          <!-- <div class="nodatatxt"><el-button size="small" @click="openadddialog"
              v-if="level == '自建知识库' || clist.includes(userInfo.phone)">录入问答</el-button>
          </div> -->
        </div>
        <span v-else>
          <div class="upbtndiv">
            <!-- <span class="filecount">共<span class="cnum">{{ wdcount }}</span>个问答，已训练<span class="cnum">{{
        wdcount }}</span>个</span> -->
            <div class="upbtn" @click="openadddialog" v-if="level == '自建知识库' || clist.includes(userInfo.phone)">
              录入问答
            </div>
          </div>
          <el-table :data="filelist" :height="updownflag ? '455' : '700'" border style="width: 100%">
            <el-table-column type="index" align="center" label="序号" width="80" />
            <el-table-column prop="question" label="问题" />
            <el-table-column align="center" label="是否AI生成" width="120">
              <template slot-scope="scope">
                <div class="aidiv" :style="{
    background: scope.row.is_ai == 0 ? 'green' : 'orange',
  }">
                  {{ !scope.row.aiGenerate ? '否' : '是' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态" width="180">
              <template slot-scope="scope">
                <div>学习完成</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="creatorName" label="创建人" width="120">
              <template slot-scope="scope">
                <div>{{ level == '自建知识库' ? scope.row.creatorName : '官方' }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="创建时间" width="180">
              <template slot-scope="scope">
                <div>{{ scope.row.createTime }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="180"
              v-if="level == '自建知识库' || clist.includes(userInfo.phone)">
              <template slot-scope="scope">
                <div>
                  <el-button size="mini" @click="editque(scope.row)" type="primary">修改</el-button>&nbsp;
                  <el-popconfirm title="确定删除选中文件吗？" @confirm="delque(scope.row.id)">
                    <el-button slot="reference" size="mini" type="danger">删除</el-button>
                  </el-popconfirm>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="No" label="状态" width="180"> -->
            <!-- </el-table-column> -->
          </el-table>
          <div class="pagelist">
            <div class="left1">
              <el-pagination background page-size="10" :total="wdcount" @current-change="pagechange">
              </el-pagination>
            </div>
            <div class="right1">问答集已有文件{{ wdcount }}个</div>
          </div>
        </span>
      </div>
    </div>
    <el-dialog title="录入问答" :append-to-body="true" :visible="dialogVisible" width="600px" :before-close="handleClose">
      <div class="mydialog">
        <p style="margin: 0 0 10px 0;">问题：</p>
        <el-input placeholder="请输入问题" v-model="inputtitle">
        </el-input>
        <p style="margin: 30px 0 10px 0;">答案：</p>
        <el-input placeholder="请输入答案" type="textarea" v-model="inputcontent" :rows="8">
        </el-input>
        <div class="upwebtip">Tip:答案文本框右下角可以拖动大小方便查看</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="submitadd">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addzjQuestion, editzjQuestion, getQuelist, delzjQuestion } from '@/api/klg'
export default {
  name: '',
  components: {

  },
  data(props) {
    return {
      filelist: [],
      fileed: 0,
      klgid: props.klgid,
      type: 'first',
      inputtitle: '',
      inputcontent: '',
      level: props.level,
      page: 1,
      wdcount: 0,
      smenu: '问答集',
      dialogVisible: false,
      quedetail: [],
      isscing: false,
      cquelist: [],
      shstatus: false,
      cqindex: 0,
      filename: '',
      cqans: '',
      cqtitle: '',
      wxlist: [],
      uptitle: '',
      upcont: '',
      updownflag: props.updownflag,
      cqloading: false,
      uploadstatus: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      clist: ['18007099098', '18057127368', '18870710935', '15070216300', '17826823305'],
      updata: [],

    }
  },
  props: {
    klgid: { type: Number },
    level: { type: String },
    updownflag: { type: Boolean }
  },
  mounted() {
    this.getquelist()

  },
  watch: {
    klgid(n) {
      console.log(n, 'data')
      this.klgid = n;
      this.getquelist()
    },
    level(n) {
      console.log(n, 'level')
      this.level = n;
    },
    updownflag(n) {
      console.log(n, 'updownflag')
      this.updownflag = n;
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },



    pagec(type) {
      this.cqindex = type == 0 ? (this.cqindex - 1) : (this.cqindex + 1);

      this.cqans = this.cquelist[this.cqindex].answer;
      this.cqtitle = this.cquelist[this.cqindex].question_title;
    },
    pagechange(e) {
      console.log(e)
      this.page = e;
      this.getquelist()
    },
    editque(row) {
      this.dialogVisible = true;
      this.inputcontent = row.answer;
      this.inputtitle = row.question;
      this.quedetail = row;
    },
    gettimeresult(date) {
      return new Date(date * 1000).getFullYear() + '-' + (new Date(date *
        1000).getMonth() + 1) + '-' + new Date(date * 1000).getDate()
    },
    openadddialog() {
      if (this.klgid == 0) {
        this.$message.error('请先创建自己的知识库')
        return;
      }
      this.dialogVisible = true;
      this.inputcontent = '';
      this.inputtitle = '';
      this.quedetail = [];
    },

    submitadd() {
      console.log(this.inputtitle, this.inputcontent)
      let params = {};
      const answer = this.inputcontent
      if (this.quedetail.id) {
        params = {
          id: this.quedetail.id,
          knowledgeBaseId: this.klgid,
          question: this.inputtitle,
          answer: answer,
          aiGenerate: false
        }
        // params = `id=${this.quedetail.id}&question_type=${this.aibox.klgname}&app_code=${this.aibox.appcode}&is_ai=0&question_title=${this.inputtitle}&answer=${answer}`
      } else {
        params = {
          knowledgeBaseId: this.klgid,
          question: this.inputtitle,
          answer: answer,
          aiGenerate: false
        }
        // params = `question_type=${this.aibox.klgname}&app_code=${this.aibox.appcode}&is_ai=0&question_title=${this.inputtitle}&answer=${answer}`
      }



      console.log(params)
      if (this.quedetail.id) {
        editzjQuestion(params).then(res => {
          this.dialogVisible = false;
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.getquelist()
          } else {
            this.$message.error(res.msg)
          }
          setTimeout(() => {
            this.getquelist()
          }, 100);
        })
      } else {
        addzjQuestion(params).then(res => {
          console.log(res);
          if (res.code == 200) {
            this.dialogVisible = false;
            setTimeout(() => {
              this.getquelist()
            }, 100);
          } else {
            this.$message.error(res.msg)
          }

        })
      }

    },
    delque(id) {
      delzjQuestion([id]).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.getquelist()
          }, 100);
        } else {
          this.$message.error(res.msg)
        }

      })
    },
    getquelist() {
      const params = {
        knowledgeBaseId: this.klgid == '' ? 0 : this.klgid,
        page: this.page,
        pageSize: '10'
      }
      getQuelist(params).then(res => {
        console.log('问答集', res)
        this.filelist = res.data.list
        this.wdcount = res.data.totalCount
      })

    },
  }
}
</script>
<style lang='less' scoped>
.klgmain3 {
  width: 100%;
  margin-top: -35px;
  border-radius: 10px 0 0 10px;
  font-family: Alibaba PuHuiTi;
  padding: 0 20px;

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #ffffff;
  }

  /*竖直的滑块*/
  ::-webkit-scrollbar-thumb {
    background-color: #7daade;
    border-radius: 2px;
    border-top: 1px solid #7daade;
    border-bottom: 1px solid #7daade;
    border-left: 1px solid #7daade;
  }

  .klgcontent {
    border-radius: 10px;
    overflow: hidden;
    height: calc(100% - 60px);

    .fbbox {
      position: relative;
      height: 20px;

      .fbtop {
        height: 40px;
        background: rgba(28, 34, 54, 0.06);
        border-radius: 6px;
        display: flex;
        width: fit-content;
        position: absolute;
        bottom: -40px;
        left: 20px;

        .fbitem {
          height: 32px;
          width: 80px;
          border-radius: 5px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          margin: 4px;
          font-size: 14px;
          background: linear-gradient(275deg, #181b40 0%, #181a37 100%);
          color: #333;
        }

        .checked {
          background: #333;
          color: #fff;
        }
      }
    }

    .detailbox {
      padding: 0 20px;

      .detail {
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-top: 10px;

        .detailitem {
          text-align: center;
          margin: 0 20px;

          .dname {
            color: #dbdbff;
            font-size: 16px;
          }

          .dvalue {
            font-size: 20px;
            background: linear-gradient(to right, #9953ff, #53a9ff);
            /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
            -webkit-background-clip: text;
            /*将设置的背景颜色限制在文字中*/
            -webkit-text-fill-color: transparent;

            span {
              font-size: 14px;
            }
          }
        }
      }

      .tcont {
        font-size: 16px;
        display: flex;
        background: linear-gradient(180deg, #181b40 0%, #181a37 100%);
        border-radius: 12px;
        margin-top: 10px;

        .zskitem {
          color: #7a7a9d;
          margin: 10px 20px;
          height: 30px;
          line-height: 30px;
        }

        .zname {
          font-size: 18px;
          color: #333;
        }
      }
    }

    .nodatabox {
      cursor: pointer;
      width: 50%;
      text-align: center;
      margin: 30px auto;

      .nodatatxt {
        font-size: 24px;
        margin-top: 30px;

        color: #333;
      }

      img {
        margin-top: 10%;
        width: 40%;
      }
    }

    .uploadbox {
      width: 100%;
      text-align: center;
      padding: 20px;
      border-radius: 8px;
      position: relative;

      #inputfile {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }

      img {
        height: 60px;
      }

      .tip {
        font-size: 18px;
        margin-top: 20px;
      }
    }

    .klgtablebox {
      width: 100%;
      text-align: center;
      border-radius: 8px;
      padding-top: 0;

      .upbtndiv {
        width: 100%;
        display: flow-root;
        font-size: 20px;
        text-align: right;

        .filecount {
          background-color: #eeecec;
          font-size: 14px;
          padding: 4px 10px;
          border-radius: 4px;
          margin-right: 15px;

          .cnum {
            color: #0988f6;
            font-size: 16px;
            margin: 0 5px;
            font-weight: bold;
          }
        }

        .upbtn {
          width: 120px;
          height: 32px;
          color: #0988f6;
          border: 1px solid #0988f6;
          border-radius: 8px;
          line-height: 32px;
          font-size: 18px;
          float: right;
          margin-bottom: 10px;
          cursor: pointer;
          text-align: center;
        }
      }

      table {
        width: 100%;
        margin-bottom: 20px;
        border-collapse: collapse;
        border-right: 1px solid #e4e4e4;
        border-top: 1px solid #e4e4e4;

        thead {
          background-color: #f2f2f3;
          color: #fff;
          width: 100%;
          display: inline-table;

          tr {
            th {
              padding: 10px;
              font-size: 16px;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }
        }

        .t1 {
          width: 5%;
        }

        .t2 {
          width: 39%;
        }

        .t3 {
          width: 12%;
        }

        .t4 {
          width: 20%;
        }

        tbody {
          max-height: 550px;
          overflow-y: auto;
          display: block;
          width: 100%;

          tr {
            cursor: pointer;
            display: flex;

            td {
              padding: 10px;
              font-size: 17px;
              border: 0;
              border-left: 1px solid #e4e4e4;
              border-bottom: 1px solid #e4e4e4;
            }
          }

          .fileed {
            background-color: #cde4f7;
          }
        }
      }

      .pagelist {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 20px 0;
        font-size: 16px;
        line-height: 30px;

        .left1 {}

        .right1 {
          color: #333;
          font-size: 13px;
          margin: 10px 0;
        }
      }
    }
  }

  .btnbox {
    display: flex;
    justify-content: center;

    .btnitem {
      width: 240px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 20px;
      background-color: #263754;
      color: white;
      font-weight: bold;
      border-radius: 8px;
      margin: 20px;
      cursor: pointer;
    }
  }

  .mydialog {
    z-index: 2001;



    p {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 5px;
    }
  }
}

.nodata {
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.upwebtip {
  font-size: 15px;
  margin: 10px 0;
  text-align: left;
  margin-bottom: 15px;
  color: #4e5e83;
}

.aidiv {
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  margin: 0 auto;
}

.wxitem {
  width: 100%;
  display: flex;

  .wxitenleft {
    width: 100px;
    text-align: left;
    line-height: 35px;
  }
}

.wxselectname {
  display: flex;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.klgmain0 {
  margin-top: 5px;
}

/deep/ .el-table tr {
  background-color: #fff !important;
  color: #333 !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: #fff !important;

  color: #333;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf,
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table--border {
  border: 1px solid #f0f1f7;
  border-right: 1px solid #f0f1f7;
  border-bottom: 1px solid #f0f1f7;
  color: #333 !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #fff;
}

/deep/ .el-pagination span {
  font-size: 15px;
}

.el-table .el-table__body td,
.el-table .el-table__header th {
  border-right: none;
  border-bottom: none;
}

.el-table .el-table__body td:last-child,
.el-table .el-table__header th:last-child {
  border-right: 1px solid #dcdfe6;
  /* 根据需要可以添加或去掉这一行代码 */
}

.el-table .el-table__footer td,
.el-table .el-table__header th {
  border-bottom: none;
}

/deep/ .el-popover {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #fff;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
}



/deep/ .el-pagination__jump,
/deep/ .el-pagination__total {
  color: #333;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #333;
}

/deep/ .el-upload-dragger {
  background: #fff;
  border-radius: 5px;
  border-color: #1c2044;
}

/deep/ .el-upload-dragger .el-upload__text {
  color: #333;
}

/deep/ .el-upload-dragger .el-upload__text em {
  color: #333;
}

//去除右侧白线
.el-table--border::after {
  width: 0px;
}

.el-table__header-wrapper {
  background: none;
}

.el-table::before {
  background: none;
}

/* 取消el-table鼠标移入高亮 
div /deep/ .el-table tbody tr:hover>td {
  background: #fff !important;
}*/

/* 取消el-table鼠标移入高亮 */
.el-table__body tr:hover>td {
  background-color: transparent !important;
}

/deep/ .el-dialog {
  background: #fff;
  border-radius: 13px;

  .mydialog {
    color: #333;
    font-size: 18px;

    .tips {
      font-size: 14px;
      margin-top: 10px;
    }



  }
}






/deep/ .el-tabs__item {
  color: #f2f2f3;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-tabs__active-bar {
  background-color: #333;
}

/deep/ .el-table__body-wrapper {
  background: #fff;
}

/deep/ .el-tabs__item.is-active {
  color: #333;
}

/deep/ .el-dialog__title,
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #333 !important;
  font-size: 22px;
}
</style>
