<template>
    <div class="mbox">
        <div class="topbox" @click="goback">找呀找呀找朋友</div>
        <img class="refimg" src="@/assets/images/nh/ref.png" @click="reflocaltion" />
        <div class="mainbox">
            <!-- <div class="leftbox">
                <img class="xzy" src="@/assets/images/zqzs/xzy.gif" />
                <img class="xzybtm" src="@/assets/images/nh/xzybtm.png" />
            </div> -->
            <div class="prize">
                <div class="qlistbox">
                    <div :style="{ fontSize: item.length > 3 ? '15px' : '20px' }"
                        :class="activeIndex.includes(i + 1) ? 'qitem active' : actlisted.includes(i + 1) ? 'qitem ed' : 'qitem'"
                        v-for="(item, i) of namelist" :key="i">{{ item }}</div>
                </div>

            </div>
        </div>
        <div class="dialog" :style="{ display: dialogflag ? '' : 'none' }">
            <div class="detailbox">
                <div class="leveltitle">一起上来玩游戏吧 ^O^</div>
                <span style="grid-template-columns: repeat(6, minmax(50px, 1fr));">
                    <div class="ditem" v-for="(item, i) in lucky" :key="i">
                        <div class="num">{{ namelist[item - 1] }}</div>
                    </div>
                </span>

                <div class="btns" @click="dialogflag = false">点击关闭</div>
            </div>

        </div>
        <div class="btmbtnsbox">
            <div class="btns" @click="goluck" :style="{ display: ing ? 'none' : '' }">点击摇人</div>
        </div>
        <audio id="cjaudio" src="@/assets/ding.mp3" style="display: none;" />
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
import { pdnhquestion, readquetext } from '@/api/bot'
import { baseUrl } from '@/axios/baseUrl'
import Recorder from 'js-audio-recorder'
export default {
    components: { VueMarkdown },
    data() {
        return {
            activeIndex: [], // 当前抽中的奖品
            lucky: [], // 抽中的商品
            // 奖品数据
            list: [],
            isset: false,
            actlist: [],
            recorder: null,
            talkflag: false,
            levelnum: 10,
            talklist: [],
            islu: false,
            audiosrc: '',
            level: '三等奖',
            nums: 149,
            actlisted: [],
            iszytalk: false,
            dialogflag: false,
            ing: false,
            namelist: ["王奇峰", "仵浩", "李琳", "张晓鸣", "陈蕾", "温裕心", "李佳丽", "钟诚芳", "靳朋伟", "江鑫", "王文桢", "殷樱", "廖文静", "李健", "张立雄", "靳朋越", "吴桂生", "申洪", "宁俊", "黄震", "乔凌涛", "刘明月", "朱雪崎", "朱尊峰", "邹海洋", "蔚义光", "黄岚", "石小红", "郭聪勇", "祁雪松", "严兴元", "廖泽帆", "徐志伟", "杨长春", "张春辉", "江学繁", "王知星", "王岗", "谢忠河", "李晨", "梁家平", "林朝福", "谢宝发", "游春梅", "何国庆", "马纪", "王迪", "李翡", "温大盛", "张勇", "朱恩汕", "游春平", "龚详龙", "陈伟平", "郭贤华", "郭厚俊", "王囿允佐", "郭迪清", "陈鹏", "曾昭民", "谢盛", "曾德威", "刘平", "李德堂", "李粹华", "朱剑威", "马海科", "黄宇豪", "王杰", "刘冲", "李子鹏", "魏粤鹏", "董智慧", "严远风", "武礼英", "潘家玲", "肖清雯", "钟媚芬", "李春艳", "刘真", "钟金明", "刘文秀", "李晓春", "沈洁", "黎信利", "张珺", "刘世芳", "谢雯娟", "邱金荣", "陈靖松", "王廷甫", "徐桂兰", "罗贤辉", "傅元华", "庄展强", "黄艳", "尹怡", "谢光璐", "刘振雄", "张治森", "武亚辉", "朱斌", "朗肖良", "苏丹", "康飞", "尹军", "温卓", "陈文英", "谢燕虹", "张卓", "伍簙侃", "黄进伟", "李志敏", "杨雪琴", "刘张愉", "朱挺荣", "梁应有", "康敏", "郑宏文", "曾永亮", "肖文祥", "王建凤", "方玉燕", "陈智慧", "申洪源", "袁立", "杨森", "黄华", "黄勇", "陈志云", "徐道生", "王朝"]
        }
    },
    computed: {

    },
    mounted() {
        console.log(this.namelist.length)
        for (let index = 1; index < 150; index++) {
            this.list.push(index)
        }
        this.actlisted = JSON.parse(window.localStorage.getItem('gactlisted')) || []
    },
    methods: {
        reflocaltion() {
            window.localStorage.removeItem('gactlisted')
            history.go(0)
        },
        goback() {
            this.$router.push('/gindex')
        },
        readans() {
            document.getElementById('ansaudio').play()
        },
        levelchange(e) {
            console.log(e.target.value)
            this.level = e.target.value
        },
        numschange(e) {
            console.log(e.target.value)
            this.nums = e.target.value
        },
        editdetail() {
            this.list = [];
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = []
            this.lucky = []
            for (let index = 1; index <= this.nums; index++) {
                this.list.push(index)
            }
            console.log(this.level)
            if (this.level == '一等奖') {
                this.levelnum = 3
            } else if (this.level == '特等奖') {
                this.levelnum = 1
            } else {
                this.levelnum = 10
            }
            setTimeout(() => {
                this.isset = false
                this.$message.success('修改成功')
            }, 200);
        },
        gozytalk() {
            this.talkflag = true
            this.iszytalk = true
            this.talklist = []
        },
        golist() {
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = []
            this.lucky = []
        },

        goluck() {
            // this.lucky = [];
            // this.actlisted = []
            this.ing = true;
            document.getElementById('cjaudio').play()
            console.log(this.levelnum)
            for (let index = 0; index < 12; index++) {
                this.startLuck(index)
            }
            setTimeout(() => {
                this.move(20)
            }, 1000);
        },
        // 传的item是点击当前元素
        startLuck(index) {
            const rannum = Math.floor(Math.random() * this.namelist.length + 1)
            console.log(this.actlisted, rannum, this.lucky)

            if (this.actlisted.includes(rannum) || rannum == 0 || this.lucky.includes(rannum)) {
                this.startLuck(index)
                return;
            }
            setTimeout(() => {
                this.actlisted.push(rannum)
                this.ing = false;
                setTimeout(() => {
                    window.localStorage.setItem('gactlisted', JSON.stringify(this.actlisted))
                }, 300);
            }, 7500);
            // 如果当前元素的id不是btn，就不执行
            // if (item.id !== 'btn') return
            this.talklist = [];
            this.iszytalk = false
            this.talkflag = false;
            this.activeIndex = []
            this.lucky[index] = rannum
            // 执行动画

        },
        toread(text) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', baseUrl + '/audioapi/tts', true);
            const formData = new FormData()
            formData.append('text', text)
            formData.append('voice', "zh-CN-YunyangNeural")
            formData.append('speed', -10)
            // const url = window.URL.createObjectURL(fileOfBlob)
            // this.src = url
            xhr.send(formData);
            const that = this;
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    const res = JSON.parse(xhr.responseText);
                    console.log(res)
                    this.audiosrc = res.audio
                    setTimeout(() => {
                        this.readans()
                    }, 500);
                }
            }
        },
        move(time) {
            if (time > 2000) { // time大于600就减速执行
                if (this.activeIndex !== this.lucky) { // 如果this.activeIndex 不等于 抽中的奖品this.lucky
                    // setTimeout(() => {
                    //     const t = this.activeIndex + 1
                    //     this.activeIndex = t % 50
                    //     this.move(time + time * 0.001)
                    // }, time)
                    this.activeIndex = []
                    this.dialogflag = true
                    this.actlist = this.lucky

                    return;
                } else {
                    // console.log('抽中的奖品是' + this.activeIndex) // 返回的是索引
                    // console.log('抽中的奖品是' + this.list[this.activeIndex].text)
                }
            } else { // time小于600就原速执行
                setTimeout(() => {
                    const t = Math.floor(Math.random() * this.namelist.length + 1)
                    this.activeIndex.splice(0, 1)
                    this.activeIndex.splice(1, 1)
                    this.activeIndex.splice(2, 1)
                    this.activeIndex.splice(3, 1)
                    this.activeIndex.push(t)
                    this.activeIndex.push(t * 2)
                    this.activeIndex.push(t * 3)
                    this.activeIndex.push(t * 4)
                    this.move(time * 1.015)
                    // console.log(time)
                }, 20)
                // }
            }
        },

    }
}
</script>
<style lang="less" scoped>
.mbox {
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/nh/bg.jpeg');
    background-size: cover;
    position: relative;
    background-repeat: no-repeat;
    padding: 0 20px;
    display: flex;
    overflow: hidden;

    .refimg {
        width: 30px;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    .levelbox {
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
        display: flex;
        color: #fff;
        align-items: center;

        img {
            width: 30px;
        }

        .levelchange,
        .myinput {
            width: 100px;
            height: 30px;
            padding: 5px 10px;
            background-color: transparent;
            color: #fff;
            border: 1px solid #9c9c9c;
            outline: none;
        }

        .mybtns {
            border-radius: 4px;
            width: 88px;
            font-size: 14px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            height: 32px;
            background: #dc96d285;
            cursor: pointer;
            line-height: 32px;
            -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
        }
    }

    .topbox {
        width: 600px;
        height: 88px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: calc(50% - 300px);
        background-image: url('../assets/images/nh/topbg.png');
        background-size: cover;
        background-repeat: no-repeat;
        font-size: 30px;
        text-align: center;
        color: #fff;
        letter-spacing: 10px;
        line-height: 58px;
    }

    .mainbox {
        display: flex;
        width: 100%;
        height: calc(100% - 20px);
        margin-top: 100px;


        .leftbox {
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: start;
            position: relative;

            .xzy {
                width: 200px;
            }

            .xzybtm {
                position: absolute;
                width: 200px;
                left: 60px;
                top: 150px;
            }
        }

        .prize {
            width: calc(100% - 80px);
            margin-left: 40px;
            height: calc(100% - 70px);
            overflow-x: hidden;
            position: relative;

            .qlistbox {
                display: grid;
                grid-template-columns: repeat(15, minmax(50px, 1fr));
                gap: 5px;

                /* 容器内的填充 */
                .qitem {
                    width: 90px;
                    height: 57px;
                    margin: 10px;
                    text-align: center;
                    font-size: 20px;
                    padding: 10px;
                    line-height: 40px;
                    box-sizing: border-box;
                    background-color: #C8459160;
                    color: #fff;
                    border-radius: 6px;

                    &.active {
                        width: 90px;
                        height: 57px;
                        line-height: 33px;
                        font-size: 15px !important;
                        background: #CF5BA0;
                        box-shadow: 0px 0px 20px 0px #CF5BA0, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
                        border-radius: 8px;
                        border: 4px solid #f895ff8f;
                        cursor: pointer;
                        -webkit-backdrop-filter: blur(7px);
                        backdrop-filter: blur(7px);

                    }




                }

                .ed {
                    opacity: 0.3;
                }
            }






        }



    }

    .btmbtnsbox {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 100%;
        display: flex;
        justify-content: center;

        .btns {
            margin-left: 20px;
            width: 218px;
            font-size: 20px;
            line-height: 40px;
            text-align: center;
            color: #fff;
            height: 45px;
            background: rgba(12, 2, 20, 0.59);
            box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
            border-radius: 8px;
            border: 4px solid #f895ff8f;
            letter-spacing: 5px;
            cursor: pointer;
            backdrop-filter: blur(7px);
        }
    }

    /deep/ .el-button--text {
        color: #fff !important;
    }

    .dialog {
        position: fixed;
        left: 0;
        top: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: #33333390;
        display: flex;
        align-items: center;
        justify-content: center;

        .detailbox {
            width: 80%;
            height: 100px;
            margin-top: -300px;

            .leveltitle {
                font-weight: normal;
                font-size: 80px;
                width: 100%;
                text-align: center;
                color: #FFFFFF;
                line-height: 97px;
                text-shadow: 0px 0px 20px #FF81ED;
            }

            span {
                display: grid;
                align-items: center;
                justify-items: center;
                justify-content: center;
                gap: 20px;

                .ditem {
                    width: 160px;




                    .num {
                        width: 100%;
                        font-size: 28px;
                        color: #fff;
                        padding: 20px;
                        top: 20px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        background: rgba(12, 2, 20, 0.59);
                        box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
                        border-radius: 8px;
                        border: 4px solid #f895ff8f;
                        position: relative;

                    }
                }

                .l1 {
                    width: 250px;
                    position: relative;

                    img {
                        width: 250px;
                    }

                    .num {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-size: 80px;
                        color: #fff;
                        top: 100px;
                        display: flex;
                        justify-content: center;

                    }
                }

                .l0 {
                    width: 250px;
                    position: relative;

                    img {
                        width: 250px;
                    }

                    .num {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        font-size: 80px;
                        color: #fff;
                        top: 100px;
                        display: flex;
                        justify-content: center;

                    }
                }
            }

        }

        .btns {
            margin-left: 20px;
            width: 218px;
            font-size: 26px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            height: 60px;
            background: rgba(12, 2, 20, 0.59);
            box-shadow: 0px 0px 20px 0px #FF81ED, inset 0px 0px 39px 0px rgba(255, 129, 237, 0.52);
            border-radius: 8px;
            border: 4px solid #f895ff8f;
            letter-spacing: 5px;
            cursor: pointer;
            backdrop-filter: blur(7px);
            margin: 50px auto;
        }
    }

}

/deep/ .el-button--text {
    color: #fff !important;
}
</style>